<template>
  <!-- 订单选项卡 -->
  <div class="member-order">
    <XtxTabs v-model:active="active">
      <XtxTabTitle v-for="status in quotationStatus" :key="status.name">
        {{ status.label }}
      </XtxTabTitle>
    </XtxTabs>
    <div class="body">
      <div class="column goods">
        <Select v-model="model" style="width: 170px">
          <Option
            v-for="item in selectTimeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </Option>
        </Select>
        <span style="margin-left: 40px">订单详情</span>
      </div>
      <div class="column consignee">采购人</div>
      <div class="column amount">订单总金额</div>
      <div class="column state">订单状态</div>
      <div class="column action">操作</div>
    </div>
    <!-- 订单列表 -->
    <div v-if="orderList" class="order-list">
      <template v-if="orderList && orderList.rows">
        <QuotationOrderItem
          v-for="item in orderList.rows"
          :key="item.id"
          :order="item"
        />
      </template>
      <div v-if="orderList?.rows?.length === 0" class="none">暂无数据</div>
    </div>
    <div style="float: right" class="table-page">
      <Page :total="orderList.records" @on-change="onChange" />
    </div>
  </div>
</template>

<script>
import { quotationStatus } from '@/api/constants'
import QuotationOrderItem from '@/views/member/order/quotation/QuotationOrderItem'
import VueEvent from '@/model/event'
// import { ref } from 'vue'

export default {
  name: 'PurchaseOrderListPage',
  components: { QuotationOrderItem },
  data () {
    return {
      active: 0,
      orderList: {},
      model: '',
      selectTimeList: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '3',
          label: '三个月',
        },
        {
          value: '12',
          label: '近1年',
        },
      ],
      numPage: 1, // 当前页号
      currentStatu: '',
      postData: {
        beginDate: '',
        endDate: '',
        productName: '',
        page: 1,
        rows: 10,
        orderId: '',
        status: '',
        month: '',
      },
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.RemoveQuotationOrderMitt()
    this.quotationOrderSrarchMitt()
    this.orderQuotationSrarchClearMitt()
  },
  methods: {
    init () {
      this.pageData()
    },
    pageData () {
      this.$store
        .dispatch('ListQuotation', this.postData)
        .then((response) => {
          this.orderList = response.result
        })
        .catch(() => {})
    },
    onChange (val) {
      this.postData.page = val
      this.pageData()
    },
    RemoveQuotationOrderMitt () {
      VueEvent.on('removeQuotationOrderMitt', (value) => {
        if (value) {
          this.postData.page = this.numPage
          this.pageData()
          // this.pageData(this.numPage)
        }
      })
    },
    orderQuotationSrarchClearMitt () {
      VueEvent.on('orderQuotationSrarchClearMitt', (value) => {
        this.active = 0
        this.postData = {
          beginDate: '',
          endDate: '',
          productName: '',
          page: 1,
          rows: 10,
          orderId: '',
          status: '',
          month: '',
        }
        this.pageData()
      })
    },
    quotationOrderSrarchMitt () {
      VueEvent.on('quotationOrderSrarchMitt', (value) => {
        if (value) {
          this.postData = {
            beginDate: value.beginTime,
            endDate: value.endTime,
            productName: value.productName,
            page: 1,
            rows: 10,
            orderId: value.orderId,
            status: '',
            month: '',
          }
          this.$store
            .dispatch('ListQuotation', this.postData)
            .then((response) => {
              this.orderList = response.result
            })
            .catch(() => {})
        }
      })
    },
  },
  watch: {
    active (val) {
      this.numPage = 1
      switch (val) {
        case 1:
          // 待确认
          this.currentStatus = 1
          this.postData.status = this.currentStatus
          this.pageData()
          break
        case 2:
          // 已取消
          this.currentStatus = 2
          this.postData.status = this.currentStatus
          this.pageData()
          break
        case 3:
          // 已完成
          this.currentStatus = 3
          this.postData.status = this.currentStatus
          this.pageData()
          break

        default:
          this.currentStatus = ''
          this.postData.status = this.currentStatus
          this.pageData()
          break
      }
    },
    model (val) {
			 if (Number(val) === 0) {
        this.postData.beginDate = ''
        this.pageData()
        return
      }
      // x月前时间 的订单
      const nowdate = new Date()
      nowdate.setMonth(nowdate.getMonth() - val)
      const y = nowdate.getFullYear()
      const m = nowdate.getMonth() + 1
      const d = nowdate.getDate()
      const retrundate = y + '-' + m + '-' + d
      this.postData.beginDate = retrundate
      this.pageData()
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {
    return {
      quotationStatus,
    }
  },
}
</script>

<style lang="less" scoped>
.member-order {
  height: 100%;
  background: #fff;
  position: relative;
}

.order-list {
  background: #fff;
  // padding: 20px;
  position: relative;
}

.loading {
  height: calc(100% - 60px);
  width: 100%;
  min-height: 400px;
  position: absolute;
  left: 0;
  top: 60px;
  //   background: rgba(255, 255, 255, 0.9) url(../../../assets/images/loading.gif)
  //     no-repeat center 18%;
}

.none {
  height: 400px;
  text-align: center;
  line-height: 400px;
  color: #999;
  background: rgba(255, 255, 255, 0.9);
}
.body {
  display: flex;
  align-items: stretch;
  margin-bottom: 8px;
  background: #f5f5f5;
  .column {
    border-left: 1px solid #f5f5f5;
    text-align: center;
    padding: 20px;
    > p {
      padding-top: 10px;
    }
    &:first-child {
      border-left: none;
    }
    &.goods {
      flex: 3;
      padding: 10;
      align-self: center;
      text-align: left;
    }
    &.consignee {
      align-self: center;
      text-align: left;
      flex: 1;
    }
    &.state {
      align-self: center;
      text-align: left;
      flex: 1;
    }
    &.amount {
      flex: 1;
      align-self: center;
      text-align: left;
      // .red {
      //   color: @priceColor;
      // }
    }
    &.action {
      // width: 140px;
      flex: 1;
      align-self: center;
      text-align: center;
      a {
        display: block;
        &:hover {
          color: @xtxColor;
        }
      }
    }
  }
}
</style>
