<template>
  <div class="order-item">
    <div class="head">
      <span>下单时间：{{ order.createDate }}</span>
      <span>订单编号：{{ order.purchasePreOrderGoodsId }}</span>
      <span class="title">采购商: {{ order.shortName }}</span>
    </div>
    <div class="body">
      <div class="column goods">
        <ul>
          <li>
            <img
              :src="require('/static/icons/logo.png')"
              style="width: 90px; height: 90px"
            />
            <div class="info">
              <p class="name ellipsis-2">{{ order.productName }}</p>
              <p class="attr ellipsis">
                <span>规格:{{ order.formate }}</span>
              </p>
              <p class="attr ellipsis">
                <span v-if="order.tradeType == '2'" class="lable1">保证金</span>
                <span v-else class="lable2">全款</span>
              </p>
              <p class="attr ellipsis">
                <span class="price">
                  ¥
                  <Numeral :value="order.price" format="0,0.00"></Numeral>
                </span>
                <span style="color: #333333; font-weight: 550">数量:</span>
                <span class="price">
                  {{ order.count }}&nbsp;{{ order.unitName }}
                </span>
              </p>
              <p class="attr ellipsis">
                <!-- <span>{{order.sendFromCityCode}}</span> -->
              </p>
            </div>
            <!-- <div class="price">¥{{ order.price }}</div>
            <div class="count">x{{ order.goodsCount }}</div> -->
          </li>
        </ul>
      </div>
      <div class="column consignee"> {{ order.name }}</div>
      <div class="column amount">
        <p class="red">
          ¥
          <Numeral :value="order.price * order.count" format="0,0.00"></Numeral>
        </p>
      </div>
      <div class="column state">
        <p v-if="order.status === 1">
          <span style="color: #333333">待确认</span>
        </p>
        <p v-if="order.status === 2">
          <span style="color: #333333">已取消</span>
        </p>
        <p v-if="order.status === 3">
          <span style="color: #15b839">已完成</span>
        </p>
        <!-- <p v-if="order.status === 4">
          <span style="color: #333333">已取消</span>
        </p> -->
        <!-- <p v-if="order.status === 5">
          <span style="color: #15b839">已确认</span>
        </p> -->
      </div>

      <div class="column action">
        <p>
          <a
            href="javascript:"
            @click="$router.push(`/user/quotation/${order.id}`)"
          >
            查看详情
          </a>
        </p>
        <p v-if="order.status === 1">
          <a href="javascript:" @click="removeOrder(order.id)">
            取消订单
          </a>
        </p>
      </div>
    </div>
  </div>
  <Modal
    v-model="modal"
    class-name="vertical-center-modal"
    title="提示"
    @on-ok="ok"
    @on-cancel="cancel"
  >
    <p>是否需要取消订单</p>
  </Modal>
</template>

<script>
import useOrderItem from '@/hooks/member/useOrderItem'
import dayjs from 'dayjs'
import { ref } from 'vue'
import VueEvent from '@/model/event'
export default {
  name: 'QuotationOrderItem',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      modal: false, // 提示框
      id: '',
      status: '',
    }
  },
  methods: {
    removeOrder (id) {
      this.id = id
      this.modal = true
    },
    ok () {
      const postData = ref({
        orderId: this.id,
      })
      this.$store
        .dispatch('RemoveQuotationOrder', postData.value)
        .then((response) => {
          if (response.returnCode === '1') {
            this.$Message.info('取消报价单成功')
            VueEvent.emit('removeQuotationOrderMitt', true)
          }
        })
        .catch(() => {})
    },
    cancel () {},
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup (props, { emit }) {
    const {
      count,
      onCancelButtonClick,
      onDeleteButtonClick,
      onConfirmReceiptButtonClick,
      onViewLogisticsButtonClick,
    } = useOrderItem(props, emit)

    return {
      count,
      dayjs,
      onCancelButtonClick,
      onDeleteButtonClick,
      onConfirmReceiptButtonClick,
      onViewLogisticsButtonClick,
    }
  },
}
</script>

<style lang="less" scoped>
.order-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  .head {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    padding: 0 20px;
    overflow: hidden;
    span {
      margin-right: 20px;
      &.down-time {
        margin-right: 0;
        float: right;
        i {
          vertical-align: middle;
          margin-right: 3px;
        }
        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }
    }
    .title {
      // color: #409eff;
    }
    .del {
      margin-right: 0;
      float: right;
      color: #999;
    }
  }
  .body {
    display: flex;
    align-items: stretch;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.goods {
        flex: 3;
        padding: 0;
        align-self: center;

        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 70px;
              height: 70px;
              border: 1px solid #f5f5f5;
            }
            .info {
              width: 220px;
              text-align: left;
              padding: 0 10px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 28px;
                  color: #333333;
                  font-weight: 550;
                }
                &.attr {
                  color: #999;
                  font-size: 12px;
                  span {
                    margin-right: 5px;
                  }
                  .lable1 {
                    background: #fff4e3;
                    font-size: 11px;
                    color: #dc9100;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                    border-radius: 4px;
                    padding: 2px;
                    width: 40px;
                    height: 22px;
                  }
                  .lable2 {
                    background: #ffeae9;
                    font-size: 11px;
                    color: #d12b23;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                    border-radius: 4px;
                    padding: 2px;
                    width: 40px;
                    height: 22px;
                  }
                  .price {
                    color: @priceColor;
                    font-size: 12px;
                    font-weight: 500;
                  }
                }
              }
            }
            .price {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      &.consignee {
        flex: 1;
        text-align: center;
        align-self: center;
        .green {
          color: @xtxColor;
        }
      }
      &.state {
        flex: 1;
        text-align: center;
        align-self: center;
        .green {
          color: @xtxColor;
        }
      }
      &.amount {
        flex: 1;
        text-align: center;
        align-self: center;
        .red {
          color: @priceColor;
        }
      }
      &.action {
        flex: 1;
				text-align: center;
        align-self: center;
        a {
          display: block;
          color: #2d9cdb;
          &:hover {
            color: @xtxColor;
          }
        }
      }
    }
  }
}
</style>
