/**
 * 一级分类数据
 * @type {string[]}
 */
export const topCategories = ['大宗', '大宗', '大宗']

/**
 * 订单状态
 * @type {{name: string, label: string}[]}
 */
export const orderStatus = [
  { name: 'all', label: '全部' },
  { name: 'unpay', label: '待确认' },
  { name: 'deliver', label: '待签合同' },
  { name: 'receive', label: '待付款' },
  { name: 'comment', label: '待补仓单' },
	{ name: 'comment', label: '待开票' },
  { name: 'complete', label: '已完成' },
  { name: 'cancel', label: '已取消' },
]
// 我的采购单
export const purchaseStatus = [
  { name: 'all', label: '全部' },
  { name: 'unpay', label: '待确认' },
	{ name: 'deliver', label: '待签合同' },
  { name: 'receive', label: '待付款' },
	{ name: 'unpay', label: '待收货' },
  { name: 'complete', label: '已完成' },
  { name: 'cancel', label: '已取消' },
]
// 我的报价单
export const quotationStatus = [
  { name: 'all', label: '全部' },
  { name: 'unpay', label: '待确认' },
  { name: 'cancel', label: '已取消' },
	{ name: 'complete', label: '已完成' },
]
// 我的求购单
export const buyingStatus = [
  { name: 'all', label: '全部' },
  // { name: 'unpay', label: '待付保证金' },
	{ name: 'deliver', label: '待报价' },
	{ name: 'unpay', label: '待确认' },
  { name: 'cancel', label: '已取消' },
	{ name: 'complete', label: '已完成' },
]

// 订单状态替换
export const orderChange = [
  { orderStatus: '0', label: '全部', statusNum: 0 },
	{ orderStatus: '1', label: '待确认', statusNum: 0 },
	{ orderStatus: '2', label: '待签合同', statusNum: 0 },
  { orderStatus: '3', label: '待付款', statusNum: 0 },
	{ orderStatus: '4', label: '待补仓单', statusNum: 0 },
	{ orderStatus: '5', label: '待开票', statusNum: 0 },
	{ orderStatus: '6', label: '待收货', statusNum: 0 },
	{ orderStatus: '7', label: '待付保证金', statusNum: 0 },
	{ orderStatus: '100', label: '已完成', statusNum: 0 },
	{ orderStatus: '1052', label: '已取消', statusNum: 0 },

]

/**
 * 取消订单的原因
 * @type {string[]}
 */
export const cancelReason = [
  '配送信息有误',
  '商品买错了',
  '重复下单/误下单',
  '忘记使用优惠券、兔币等',
  '其他渠道价格更低',
  '不想买了',
]
